.Header {
    background-color: #030308;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 30px;
    height: 25px;
    position: relative;
    top: 0;
    /*box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);*/
}
@media (min-width: 950px){
    .Header-nav {
        display: flex;
        width: 100%;
        min-width: 950px;
        margin: 0 auto;
        justify-content: space-between;
    }
}

.Header-navList {
    display: flex;
    list-style: none;
    /*padding: 0;*/
    /*padding: 20px 0px;*/
    align-items: center;
    text-align: center;
    padding-inline-start: 0;
}

.Header-navList li {
    margin-right: 16px;
    padding-right: 20px;
    padding-left: 20px;
}

.Header-nav img {
    width: 40px;
    height: 40px;
    clip-path: inset(0 0 0 0);
    /*transform: translateY(8px);*/
}

.Header-navList li:last-child {
    margin-right: 0;
}

.Header-navList a {
    font-weight: 600;
    font-family: "Bradley Hand ITC", sans-serif;
    /*color: #ffffff;*/
    font-size: 20px;
    text-decoration: none;
}

.connbtn {
    height: 35px;
    align-items: center;
    text-align: center;
}
.d2 {
    align-items: center;
    text-align: center;
    display: flex;
}

.selected {
    color: #d1477d; /* 您可以将这里的颜色更改为您喜欢的任何颜色 */
}
.no_selected {
    color: white;
}
 /*在您的CSS文件中添加以下内容 */
.navLink {
    transition: color 0.4s;
}
.d0 {
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
}
