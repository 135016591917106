.Home {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    /*背景X轴图填充，自动缩放*/

    /*上下边缘加上渐变蒙版，颜色是黑色*/
    /*background-image: linear-gradient(rgba(0, 0, 0, 0.8) 90%, rgba(0,0,0,0.8) 80%), url("/public/img/background.png");*/

    height: calc(100vh - 80px);
    background-color: #0d1026;
}


.Main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.9) 0%, rgba(0,0,0,0) 10%), linear-gradient(rgba(0, 0, 0, 0) 70%, rgba(14,16,37,1) 95%), url("/public/img/bk.png") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 700px;
}


@keyframes rainbow {
    0% {border-color: red;}
    14% {border-color: orange;}
    28% {border-color: yellow;}
    42% {border-color: green;}
    57% {border-color: blue;}
    71% {border-color: indigo;}
    85% {border-color: violet;}
    100% {border-color: red;}
}

.buttonBuy {
    /*background-color: #4CAF50;*/
    background-color: rgb(90, 192, 119);
    border: 1px solid;
    color: white;
    padding: 12px 24px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    font-weight: bolder;
    font-family: "Bradley Hand ITC", sans-serif;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.1s;
    user-select: none;
    animation: rainbow 10s linear infinite;
    transition: all 0.4s ease-in-out; /* 添加过渡动画 */
}

.buttonBuy:hover {
    background-color: #45a049;
}
